<template>
  <!-- Footer -->
  <footer class="my-5 mx-auto d-flex footer">

    <!-- Details -->
    <div class="d-none d-sm-block col me-4">
      <h1>Zac's Fish Bar</h1>
      <small>We are a well established, widely known and loved fish and chip takeaway in the heart of Maltby.
      </small>
      <div class="mt-4 align-self-start d-flex align-items-center">
        <a target="_blank" href="https://apps.apple.com/gb/app/zacs-fish-bar/id6451218673" class="btn btn-primary me-2">
          <img alt="appstore" class="me-2" style="height:20px;" src="../assets/img/app-store.webp">
          <span>Apple Store</span>
        </a>
         <a target="_blank" href="https://play.google.com/store/apps/details?id=com.orderopia.zacsfishbar" class="btn btn-primary">
          <img alt="googleplay" class="me-2" style="height:20px;" src="../assets/img/google-play.webp">
          <span>Play Store</span>
        </a> 

      </div>
    </div>

    <!-- Site Map -->
    <div class="ms-4 ms-sm-0 me-4 sitemap col d-flex flex-column">
      <h3 class="mb-3">SITEMAP</h3>
      <router-link to="">Home</router-link>
      <router-link to="/our-food">Our Food</router-link>
      <router-link to="/contact">Contact</router-link>

    </div>
    <!-- Social Media -->
    <div class="social-media-container me-4 me-sm-0 col-auto d-flex">

      <a href="https://www.facebook.com/zacsfishbar/?locale=en_GB" class="btn-icon me-3">
        <img alt="facebook" src="../assets/img/facebook.webp">
      </a>
    </div>

  </footer>
</template>

<script>
export default {
  name: 'RuggedSeparator',
  props: {
    isTop: {
      type: Boolean,
      default: true
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  max-width: 800px;
  width: 100%;
}

.sitemap a {
  height: 30px;
}

@media (max-width:800px) {



  .social-media-container {
    flex-direction: column;
  }

  .social-media-container * {
    margin-bottom: 10px;
  }



}
</style>
